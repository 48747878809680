import { useSession } from 'next-auth/react';
import { IBrandData } from '../interfaces/IBrandData';
import { Country } from '../interfaces/Country';

export enum UserStatus {
  Unknown,
  Unauthenticated,
  AuthenticatedRegular,
  AuthenticatedHumanResources,
}

export const useUserStatus = (brand: IBrandData): [UserStatus] | [UserStatus, Array<Country>] => {
  const { data: session, status } = useSession();

  if (status === 'loading') {
    // console.log('useUserStatus: loading');

    return [UserStatus.Unknown];
  }

  if (!session || !session.user || !session.user.email) {
    // console.log('useUserStatus: unauthenticated');

    return [UserStatus.Unauthenticated];
  }

  if (brand.isHr === true) {
    // console.log('useUserStatus: authenticated ALL');

    return [UserStatus.AuthenticatedHumanResources];
  } else if (Array.isArray(brand.isHr) && brand.isHr.length > 0) {
    // console.log('useUserStatus: authenticated SOME', brand.isHr);

    return [UserStatus.AuthenticatedHumanResources, brand.isHr];
  }

  // console.log('useUserStatus: authenticated REGULAR');

  return [UserStatus.AuthenticatedRegular];
};
