import coreTheme from '@/theme/core';
import { global as coreGlobal } from '@/theme/core/foundations/styles/global';
import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';

export default extendTheme({
  ...coreTheme,
  colors,
  fonts,
  styles: {
    global: {
      ...coreGlobal,
      html: {
        letterSpacing: '-0.5px',
      },
    },
  },
});
