import {
  Box,
  Button,
  Heading,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  RadioGroup,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { MenuListSelection } from '../selections/MenuListSelection';
import { useTranslation } from 'react-i18next';
import { useBrand } from '../../../hooks/useBrand';
import { useRouter } from 'next/router';
import { ChartDetailLevel } from '../../../interfaces/ChartDetailLevel';
import { Country } from '@/interfaces/Country';
import { usePreferences } from '../../../contexts/PreferencesContext';
import { countries as availableCountries } from '@/constants/countries';
import { useCallback, useMemo, useRef } from 'react';
import { LanguageSelection } from '../selections/LanguageSelection';
import { MenuRadioItem } from '../Menu/MenuRadioItem';
import { PRIMARY_LIST, SECONDARY_LIST, SPECIAL_CURRENCIES } from '../../../constants/currencies';

interface IPreferencesProps {
  details?: Array<ChartDetailLevel>;
  country?: Country;
}

const levels = ['basic', 'medium', 'all'] as const;

export const PreferencesModal = ({
  isOpen,
  onClose,
  limitLanguages,
  country,
  details: availableDetails,
}: {
  isOpen: boolean;
  onClose(): void;
  limitLanguages: Array<string>;
} & IPreferencesProps) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const { preferences, setPreferences } = usePreferences();
  const { currency, secondary, details = 1, activeCountry = brand.countries[0] } = preferences;
  const initialCountry = useRef(activeCountry);
  const router = useRouter();

  const onChange = useCallback(
    (key: keyof typeof preferences) => {
      return (value: (typeof preferences)[typeof key]) => {
        setPreferences({
          ...preferences,
          [key]: value,
        });
      };
    },
    [setPreferences, preferences],
  );

  const countries = brand.countries
    ? availableCountries.filter(({ key }) => brand.countries.includes(key))
    : availableCountries;
  const activeCountryItem = availableCountries.find(({ key }) => key === activeCountry);

  const availableCurrencies = [...SPECIAL_CURRENCIES, ...(brand.currencies ?? [])];
  const primaryList = PRIMARY_LIST.filter(({ key }) => availableCurrencies.includes(key));
  const secondaryList = SECONDARY_LIST.filter(({ key }) => availableCurrencies.includes(key));

  const initialCurrency = useMemo(
    () => primaryList.find(({ key }) => key === currency) || primaryList[0],
    [currency, primaryList],
  );

  const initialSecondary = useMemo(
    () => secondaryList.find(({ key }) => key === secondary) || secondaryList[0],
    [secondary, secondaryList],
  );

  const shouldUpdate = initialCountry.current !== activeCountry;

  const updatePreferences = () => {
    if (initialCountry.current !== activeCountry) {
      router.push(`/${activeCountry}`);
    }
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={1} maxW={['sm', 'md', 'xl']} w="max-content">
          <ModalBody display="flex" justifyContent="center" p={0}>
            <Stack
              direction={['column']}
              w="100%"
              py={[4, 6, 10]}
              px={[6, 8, 10]}
              justifyContent="space-between"
              alignItems={['center', 'center', 'flex-start']}
            >
              <Heading as="h2" fontSize="xl" pb={8}>
                {t('common.settings')}
              </Heading>

              <LanguageSelection limitLanguages={limitLanguages} />

              {!country && countries.length > 1 && (
                <MenuListSelection
                  options={countries}
                  value={preferences.activeCountry}
                  defaultValue={activeCountryItem}
                  label={'common.countries'}
                  onChange={onChange('activeCountry')}
                />
              )}

              <MenuListSelection
                options={primaryList}
                value={preferences.currency}
                defaultValue={initialCurrency}
                label={'common.currencies.primary'}
                onChange={onChange('currency')}
              />

              <MenuListSelection
                options={secondaryList}
                value={preferences.secondary}
                defaultValue={initialSecondary}
                label={'common.currencies.secondary'}
                onChange={onChange('secondary')}
              />
              <Box w="100%">
                <Heading as="h2" fontSize="xl">
                  {t('common.chart')}
                </Heading>
                <Box mt={4} mb={8}>
                  <Switch
                    variant="default"
                    onChange={(e) => onChange('chart')(e.target.checked)}
                    isChecked={preferences.chart}
                    colorScheme="brand"
                  />
                </Box>
              </Box>

              {availableDetails && availableDetails.length > 0 && (
                <Box>
                  <Heading as="h2" fontSize="xl">
                    {t('common.details.label')}
                  </Heading>
                  <MenuOptionGroup defaultValue={details.toString()} type="radio">
                    <RadioGroup
                      my={8}
                      value={preferences.details.toString()}
                      onChange={(val) => onChange('details')(parseInt(val, 10) as ChartDetailLevel)}
                      w={[300, 400, 400]}
                    >
                      {availableDetails.map((value) => (
                        <MenuRadioItem
                          value={value.toString()}
                          key={value}
                          label={t(`common.details.${levels[value]}`)}
                        />
                      ))}
                    </RadioGroup>
                  </MenuOptionGroup>
                </Box>
              )}

              <Button variant="action" onClick={updatePreferences}>
                {shouldUpdate ? t('common.buttons.update') : t('common.buttons.close')}
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
