import { Box, MenuItem, Radio, useColorModeValue } from '@chakra-ui/react';
import { FlagIcon } from '../FlagIcon';

export const MenuRadioItem = ({
  value,
  label,
  icon,
  changeOption,
}: {
  value: string;
  label: string;
  icon?: string;
  changeOption?: (value: string) => void;
}) => (
  <MenuItem onClick={() => changeOption?.(value)} bg="transparent">
    <Radio value={value} w="100%" colorScheme="brand" borderColor={useColorModeValue('gray.500', 'gray.200')}>
      <Box display="flex" w="100%">
        {label}
        <Box flex={1} />
        {icon && <FlagIcon src={icon} {...{ position: 'absolute', right: '0' }} />}
      </Box>
    </Radio>
  </MenuItem>
);
