import { Global } from '@emotion/react';

const FONTS = {
  infinum: `
  @font-face {
    font-family: 'Neue Haas Grotesk Text';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/infinum/Linotype-NHaasGroteskTXPro-75Bd.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Neue Haas Grotesk Text';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/infinum/Linotype-NHaasGroteskTXPro-55Rg.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Neue Haas Grotesk Text';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/infinum/Linotype-NHaasGroteskTXPro-65Md.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Neue Haas Grotesk Display';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/infinum/Linotype-NHaasGroteskDSPro-75Bd.woff2') format('woff2');
  }
    `,
  pdc: `
  @font-face {
    font-family: 'PorscheNext';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/pdc/PorscheNextWLa-Bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'PorscheNext';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/pdc/PorscheNextWLa-Thin.woff2') format('woff2');
  }
  @font-face {
    font-family: 'PorscheNext';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/pdc/PorscheNextWLa-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'PorscheNext';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/pdc/PorscheNextWLa-Bold.woff2') format('woff2');
  }`,
  productive: `
  @font-face {
    font-family: 'Inter Var';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/productive/Inter-roman.var.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Inter Var';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/productive/Inter-roman.var.woff2') format('woff2');
  }
  `,
};

const Fonts = ({ brand }: { brand: keyof typeof FONTS }) => <Global styles={FONTS[brand]} />;

export default Fonts;
