import { useEffect } from 'react';

import i18n from '@/i18n';
import { languageList } from '@/constants/languages';

export function useLanguage(limitLanguages: Array<string>) {
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    window.localStorage.setItem('lang', lang);
  };

  const languages = (
    limitLanguages ? languageList.filter(({ key }) => limitLanguages.includes(key)) : languageList
  ).map(({ key, label, flagPath }) => ({ label, value: key, icon: flagPath }));

  useEffect(() => {
    const languages = window.navigator.languages
      .map((lang) => lang.split('-')[0])
      .map((lang) => (lang === 'ba' ? 'hr' : lang))
      .map((lang) => (lang === 'cnr' ? 'sr' : lang))
      .filter((lang) => (limitLanguages ? limitLanguages.includes(lang) : false));
    const newLanguage = window.localStorage.getItem('lang') || languages[0] || 'en';

    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
  }, [limitLanguages]);

  return { languages, changeLanguage };
}
