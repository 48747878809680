export const hrkFormatterInstance = new Intl.NumberFormat('hr-HR', {
  style: 'currency',
  currency: 'HRK',
  currencyDisplay: 'narrowSymbol',
});

export const eurFormatterInstance = new Intl.NumberFormat('hr-HR', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'narrowSymbol',
});

export const mkdFormatterInstance = new Intl.NumberFormat('mk-MK', {
  style: 'currency',
  currency: 'MKD',
  currencyDisplay: 'narrowSymbol',
});

export const usdFormatterInstance = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
});

export const gbpFormatterInstance = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
  currencyDisplay: 'narrowSymbol',
});

export const dateFormatter = (date: Date | number, locale?: string) => {
  const dateInst = date instanceof Date ? date : new Date(date);

  return dateInst.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const utcDate = (date: Date | number) => {
  const dateInst = date instanceof Date ? date : new Date(date);

  return new Date(dateInst.getTime() + dateInst.getTimezoneOffset() * 60 * 1000);
};

export const utcDateFormatter = (date: Date | number) => {
  const dateInst = date instanceof Date ? date : new Date(date);
  const utcDate = new Date(dateInst.getTime() + dateInst.getTimezoneOffset() * 60 * 1000);

  return utcDate.toISOString().split('T')[0];
};

export function roundMoney(value: number) {
  return Math.round(value * 100) / 100;
}
