export const Button = {
  variants: {
    menu: {
      background: 'brand.500',
      color: 'white',
      px: 2,
      py: 1,
      mx: 1,
      height: 'auto',
    },
    activemenu: {
      background: 'gray.300',
      px: 2,
      py: 1,
      mx: 1,
      height: 'auto',
    },
    menuitem: {
      background: 'transparent',
      color: 'black',
      width: '100%',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      justifyContent: 'flex-start',
      px: 5,
      py: 2,
      borderRadius: 0,

      _dark: {
        color: 'white',
      },

      _hover: {
        bg: 'gray.100',

        _dark: {
          bg: 'gray.700',
        },
      },
    },
    action: {
      background: 'brand.500',
      color: 'white',
      alignSelf: 'center',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.5,
      borderRadius: 28,
      px: 30,
      py: 15,
    },
  },
};
