export const Stat = {
  baseStyle: {
    label: {
      color: 'black',
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      fontWeight: 400,
      mb: 1,
      _dark: {
        color: 'white',
      },
    },
    number: {
      color: 'black',
      fontSize: 36,
      lineHeight: 1.16,
      letterSpacing: '-0.5px',
      fontWeight: 700,
      mb: 4,
      _dark: {
        color: 'white',
      },
    },
  },
};
