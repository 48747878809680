export const Slider = {
  baseStyle: {
    track: {
      bg: 'gray.300',
      borderRadius: 6,
      h: 1.5,
    },
    thumb: {
      bg: 'brand.500',
      boxSize: 6,
    },
    filledTrack: {
      bg: 'brand.500',
    },
  },
};
