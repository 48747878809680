import { Image, Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

interface IFlagIconProps extends BoxProps {
  src?: string;
}

export const FlagIcon: FC<IFlagIconProps> = ({ src, ...rest }) => (
  <Box boxSize="22px" {...rest}>
    <Image w="100%" src={src} role="presentation" />
  </Box>
);
