import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { createContext } from '@chakra-ui/react-utils';

import { Currency } from '@/enums/Currency';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { ChartDetailLevel } from '@/interfaces/ChartDetailLevel';
import { Country } from '@/interfaces/Country';

export interface IPreferences {
  chart: boolean;
  details: ChartDetailLevel;
  currency: Currency;
  secondary?: Currency;
  activeCountry: Country;
}

const initialPreferences: IPreferences = {
  chart: true,
  details: 1,
  currency: Currency.Default,
  secondary: Currency.Default,
  activeCountry: 'hr',
};

const [Provider, usePreferences] = createContext<{
  preferences: IPreferences;
  setPreferences: Dispatch<SetStateAction<IPreferences>>;
}>({ name: 'PreferencesContext', errorMessage: 'usePreferences must be used within a PreferencesProvider' });

interface IPreferencesProviderProps {
  children?: ReactNode;
}
const PreferencesProvider: FC<IPreferencesProviderProps> = ({ children }) => {
  const [preferences, setPreferences] = useLocalStorage('preferences', initialPreferences);

  return <Provider value={{ preferences, setPreferences }}>{children}</Provider>;
};

export { PreferencesProvider, usePreferences };
