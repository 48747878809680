export const Divider = {
  variants: {
    title: {
      borderColor: 'gray.400',
      borderWidth: 1,
      borderStyle: 'solid',
      my: 2,
    },
    amount: {
      borderColor: 'gray.200',
      borderWidth: 1,
      borderStyle: 'solid',
      my: 1,
    },
  },
};
