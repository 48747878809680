import { FC, ReactNode, useMemo, useState } from 'react';
import { createContext } from '@chakra-ui/react-utils';
import { Brand } from '../enums/Brand';
import { IBrandData } from '../interfaces/IBrandData';
import { IUserData } from '../interfaces/IUserData';

type IKey = string;
type IValue = IKeysProviderState[keyof IKeysProviderState] | string;

interface IKeysValue {
  setKey(key: IKey, value: IValue): void;
  getKey(key: IKey): IValue | undefined;
}
const [Provider, useKeys] = createContext<IKeysValue>({
  name: 'KeysContext',
  errorMessage: 'useKeys must be used within a KeysProvider',
});

interface IKeysProviderProps {
  children: ReactNode;
  brand: Brand;
  brandData: IBrandData;
  userData: IUserData;
  showSalary: boolean;
}

type IKeysProviderState = Omit<IKeysProviderProps, 'children'> | Record<string, string>;

const KeysProvider: FC<IKeysProviderProps> = ({ children, brand, brandData, userData, showSalary }) => {
  const [state, setState] = useState<IKeysProviderState>({ brand, brandData, userData, showSalary });
  const value = useMemo(() => {
    return {
      setKey: (key: IKey, value: IValue) => {
        // TODO: why do we need timeout here?
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setState((state) => (state[key] === value ? state : { ...state, [key]: value }));
        }, 0);
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getKey: (key: IKey) => state[key as string] as IValue | undefined,
    };
  }, [state]);

  return <Provider value={value}>{children}</Provider>;
};

export { KeysProvider, useKeys };
