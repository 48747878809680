import { createLogger, format, transports } from 'winston';

export const logger = createLogger({
  level: 'info',
  format: format.combine(
    format.timestamp({
      format: 'YYYY-MM-DD HH:mm:ss',
    }),
    format.errors({ stack: true }),
    format.splat(),
    format.json(),
  ),
  defaultMeta: { service: 'salary-calculator', env: process.env.APP_ENV },
  transports: [
    new transports.Console(),
    // new transports.File({ filename: 'salary-error.log', level: 'error' }),
    // new transports.File({ filename: 'salary-combined.log' }),
  ],
});
