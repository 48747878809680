import { useColorModeValue, useColorMode, useCallbackRef, HStack, Switch } from '@chakra-ui/react';

import { ChangeEvent } from 'react';

export const ColorModeSelection = () => {
  const { toggleColorMode } = useColorMode();
  const colorScheme = useColorModeValue('black', 'white');

  const handleChange = useCallbackRef((_event: ChangeEvent<HTMLInputElement>) => toggleColorMode(), [toggleColorMode]);

  return (
    <HStack pr={8}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          // eslint-disable-next-line max-len
          d="M4.44043 16.2944L4.30082 15.4263L2.96947 15.6404L3.70242 16.7723L4.44043 16.2944ZM9.82115 4.18669L9.68154 3.31862L8.35019 3.53273L9.08314 4.6646L9.82115 4.18669ZM10.2081 8.48209C10.2081 11.9828 7.64979 14.8877 4.30082 15.4263L4.58004 17.1625C8.76785 16.489 11.9666 12.8599 11.9666 8.48209H10.2081ZM9.08314 4.6646C9.79481 5.76361 10.2081 7.07324 10.2081 8.48209H11.9666C11.9666 6.72396 11.4496 5.08389 10.5592 3.70879L9.08314 4.6646ZM9.96075 5.05477C10.327 4.99586 10.7033 4.96517 11.0873 4.96517V3.20671C10.6094 3.20671 10.1398 3.24492 9.68154 3.31862L9.96075 5.05477ZM11.0873 4.96517C14.972 4.96517 18.1212 8.11433 18.1212 11.999H19.8796C19.8796 7.14316 15.9432 3.20671 11.0873 3.20671V4.96517ZM18.1212 11.999C18.1212 15.8837 14.972 19.0329 11.0873 19.0329V20.7913C15.9432 20.7913 19.8796 16.8549 19.8796 11.999H18.1212ZM11.0873 19.0329C8.61109 19.0329 6.43305 17.754 5.17844 15.8165L3.70242 16.7723C5.2674 19.1891 7.98999 20.7913 11.0873 20.7913V19.0329Z"
          fill={colorScheme}
        />
      </svg>

      <Switch
        variant="colorMode"
        checked={colorScheme === 'black'}
        size="md"
        colorScheme="gray"
        onChange={handleChange}
      />
    </HStack>
  );
};
