import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { components } from './components';
import { fonts } from './foundations/fonts';
import { fontSizes } from './foundations/fontSizes';
import { shadows } from './foundations/shadows';
import { global } from './foundations/styles/global';

export default extendTheme({
  initialColorMode: 'system',
  useSystemColorMode: false,
  fonts,
  shadows,
  styles: {
    global,
  },
  fontSizes,
  colors,
  components,
});
