export const languageList = [
  {
    key: 'en',
    label: 'English',
    flagPath: '/flags/us.svg',
  },
  {
    key: 'hr',
    label: 'Hrvatski',
    flagPath: '/flags/hr.svg',
  },
  {
    key: 'cnr',
    label: 'Crnogorski',
    flagPath: '/flags/me.svg',
  },
  {
    key: 'mk',
    label: 'Македонски',
    flagPath: '/flags/mk.svg',
  },
  {
    key: 'sl',
    label: 'Slovenščina',
    flagPath: '/flags/si.svg',
  },
  {
    key: 'sr',
    label: 'Srpski',
    flagPath: '/flags/sr.svg',
  },
] as const;
