export const Checkbox = {
  baseStyle: {
    color: 'white',
    colorScheme: 'black',
  },
  defaultProps: {
    size: 'lg',
    icon: () => null,
    iconSize: 0,
    _checked: {
      color: 'brand.500',
    },
  },
};
