export const global = {
  body: {
    backgroundColor: 'white',
    color: 'black',
  },
  'body.chakra-ui-dark': {
    backgroundColor: 'black',
    color: 'white',
  },
  'svg text': {
    fontFamily: 'body',
    fill: 'black',
    color: 'black',
    opacity: 1,
  },
  '.chakra-ui-dark svg text': {
    color: 'white',
    fill: 'white',
  },
  '.rdk-portal > div': {
    backgroundColor: 'brand.500',
  },
  'html, body, #__next': {
    height: '100%',
  },
  '.chakra-portal .chakra-popover__popper': {
    pointerEvents: 'none',
  },
};
