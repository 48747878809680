import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, IconButton, Input, InputGroup, InputRightAddon, Stack, Text } from '@chakra-ui/react';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { IDebugData } from '../../interfaces/IDebugData';
import Link from 'next/link';

function saveOptions(options: IDebugData) {
  document.cookie = `__debug__=${JSON.stringify(options)}; path=/`;
}

interface IDebugProps {
  log?: string;
}

const Refresh = ({ type }: { type: string }) => {
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    fetch(`/api/refresh/${type}`).finally(() => setRefreshing(false));
  }, [setRefreshing, type]);

  return (
    <Button size="xs" disabled={refreshing} isLoading={refreshing} onClick={onRefresh}>
      {type}
    </Button>
  );
};

export const Debug: FC<IDebugProps> = ({ log }) => {
  const [options, setOptions] = useState<IDebugData>({ open: false });

  useEffect(() => {
    let data = {};

    try {
      data = JSON.parse(document.cookie.replace(/(?:(?:^|.*;\s*)__debug__\s*=\s*([^;]*).*$)|^.*$/, '$1')) as IDebugData;
    } catch {
      // noop
    }
    setOptions(data);
  }, []);

  const onUserChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newOptions = { ...options, user: e.target.value };

    setOptions(newOptions);
    saveOptions(newOptions);
  };

  const onToggle = () => {
    const newOptions = { ...options, open: !options.open };

    setOptions(newOptions);
    saveOptions(newOptions);
  };

  return (
    <Box bg="red.200" pos="fixed" top={0} left={0} p={1} zIndex={5} borderBottomEndRadius={2}>
      <IconButton aria-label="Debug options" variant="ghost" onClick={onToggle} icon={<WarningTwoIcon />} size="sm" />
      {options.open && (
        <>
          <Text ml={1} display="inline-block">
            Debug options
          </Text>
          <Box>
            <form onSubmit={() => window.location.reload()}>
              <Stack direction={['column', 'row', 'row']} alignItems={['flex-start', 'center', 'center']}>
                <span>User:</span>
                <InputGroup size="sm">
                  <Input value={options.user} placeholder="name.surname" onChange={onUserChange} />
                  <InputRightAddon>@infinum.com</InputRightAddon>
                </InputGroup>
                <Button type="submit" size="sm">
                  Apply
                </Button>
              </Stack>
            </form>
            <Divider my={2} />
            <Stack direction={['column', 'column', 'row']} alignItems={['flex-start', 'flex-start', 'center']}>
              <span>Refresh:</span>
              <Refresh type="salary" />
              <Refresh type="bonuses" />
              <Refresh type="commissions" />
              <Refresh type="vacation" />
              <Refresh type="education" />
              <Refresh type="equipment" />
              <Refresh type="fun-and-games" />
              <Refresh type="mini-tb" />
              <Refresh type="other-benefits" />
              <Refresh type="grants" />
              <Refresh type="company-value" />
            </Stack>
            <Divider my={2} />
            <Link href="/admin">Admin</Link>
            <pre>{log}</pre>
          </Box>
        </>
      )}
    </Box>
  );
};
