import { languageList } from '@/constants/languages';
import i18n from '@/i18n';
import { MenuListSelection } from './MenuListSelection';
import { useLanguage } from '@/hooks/useLanguage';

export const LanguageSelection = ({ limitLanguages }: { limitLanguages: Array<string> }) => {
  const { languages, changeLanguage } = useLanguage(limitLanguages);

  if (limitLanguages && languages.length < 2) {
    return null;
  }

  const activeLanguage =
    languageList.find(({ key }) => key === i18n.language) ||
    languageList.find(({ key }) => key === 'en') ||
    languageList[0];

  console.log(languageList, activeLanguage, i18n.language);

  return (
    <MenuListSelection
      options={languageList}
      value={activeLanguage.key}
      defaultValue={activeLanguage}
      label={'common.language'}
      onChange={changeLanguage}
    />
  );
};
