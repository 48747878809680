export const Text = {
  variants: {
    highlighted: {
      fontSize: '2xl',
      lineHeight: 'none',
      mr: 2,
    },
    normal: {
      fontSize: 'lg',
      lineHeight: 'none',
      mr: 2,
    },
    label: {
      fontSize: ['md', 'lg'],
      lineHeight: 'lg',
    },
    subtitle: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    'highlighted-label': {
      fontSize: ['md', 'lg'],
      lineHeight: 'lg',
      fontWeight: 'bold',
    },
    info: {
      fontSize: 14,
      lineHeight: 1.3,
      letterSpacing: '0.2px',
      textAlign: 'right',
      color: 'gray.600',
    },
  },
};
