import { Brand } from '../enums/Brand';
import infinumTheme from '@/theme/infinum';
import pdcTheme from '@/theme/pdc';
import productiveTheme from '@/theme/productive';
import { DEFAULT_BRAND } from '../constants/app';

const THEMES = {
  infinum: infinumTheme,
  pdc: pdcTheme,
  productive: productiveTheme,
};

export function useTheme(brand: Brand = DEFAULT_BRAND) {
  return THEMES[brand];
}
