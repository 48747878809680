import { Show, HStack, Heading, Box, Spacer, Divider, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

import { HeaderContainer } from '@/components/shared/Header/HeaderContainer';
import { Preferences } from '@/components/shared/preferences/Preferences';
import { ChartDetailLevel } from '@/interfaces/ChartDetailLevel';
import { Country } from '@/interfaces/Country';
import { useBrand } from '../../../hooks/useBrand';
import { ColorModeSelection } from '../selections/ColorModeSelection';
import { useKeys } from '../../../contexts/KeysContext';
import { IUserData } from '../../../interfaces/IUserData';
import { HeaderItem } from '../Header/HeaderItem';
import { useRouter } from 'next/router';
import { ILanguage, initI18n } from '../../../i18n';
import { Brand } from '../../../enums/Brand';

export const Layout = ({
  children,
  country,
  limitLanguages,
  details,
}: {
  children: ReactNode;
  country?: Country;
  limitLanguages?: Array<ILanguage>;
  details?: Array<ChartDetailLevel>;
}) => {
  initI18n(limitLanguages);
  const { t } = useTranslation();
  const brand = useBrand();
  const logo = useColorModeValue(brand.logo.white, brand.logo.black);
  const keys = useKeys();
  const router = useRouter();
  const color = useColorModeValue('black', 'white');

  const userData = keys.getKey('userData') as IUserData;
  const showSalary = keys.getKey('showSalary') as boolean;
  const salary = showSalary;
  const compensation = Boolean(userData.compensation);
  const stocks = Boolean(userData.grant);
  const sectionCount = [salary, compensation, stocks].filter(Boolean).length;
  const pageTitle = brand?.brand === Brand.Infinum ? t('common.title2') : t('common.title');

  return (
    <>
      <HeaderContainer>
        <HStack>
          <Box py={8}>
            <Heading as="h1" fontSize={['4xl', '2xl']} color={color}>
              <HStack>
                <Box h={5} as="img" role="presentation" src={logo} mr={2} />
                <Show above="md">
                  <>{pageTitle}</>
                </Show>
              </HStack>
            </Heading>
          </Box>
          <Spacer />
          <Show above="md">
            {sectionCount > 1 && (
              <HStack pr={6}>
                {salary && (
                  <HeaderItem active={router.pathname !== '/stocks' && router.pathname !== '/compensation'} href="/">
                    {t('common.salary')}
                  </HeaderItem>
                )}
                {compensation && (
                  <HeaderItem active={router.pathname === '/compensation'} href="/compensation">
                    {t('compensation.label')}
                  </HeaderItem>
                )}
                {stocks && (
                  <HeaderItem active={router.pathname === '/stocks'} href="/stocks">
                    {t('common.stocks')}
                  </HeaderItem>
                )}
              </HStack>
            )}
            {(!limitLanguages || limitLanguages.length > 0) && (
              <HStack py={8}>
                <ColorModeSelection />
                <Preferences country={country} details={details} limitLanguages={limitLanguages ?? brand.languages} />
              </HStack>
            )}
          </Show>
          <Show below="md">
            <Preferences country={country} details={details} limitLanguages={limitLanguages ?? brand.languages} />
          </Show>
        </HStack>
        <Show below="md">
          <Divider />
          <Heading as="h1" fontSize={['4xl', '2xl']} w="80%" mt={[2, 5]} mb={[4, 10]} color={color}>
            <>{t('common.title')}</>
          </Heading>
        </Show>
      </HeaderContainer>
      {children}
    </>
  );
};
