import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Divider } from './Divider';
import { GridItem } from './GridItem';
import { Link } from './Link';
import { Select } from './Select';
import { Text } from './Text';
import { Tooltip } from './Tooltip';
import { Switch } from './Switch';
import { Stat } from './Stat';
import { Slider } from './Slider';

export const components = {
  GridItem,
  Link,
  Text,
  Divider,
  Button,
  Select,
  Checkbox,
  Tooltip,
  Switch,
  Stat,
  Slider,
};
