import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../src/translations/en.json';
import hr from '../src/translations/hr.json';
import sl from '../src/translations/sl.json';
import sr from '../src/translations/sr.json';
import mk from '../src/translations/mk.json';
import { languageList } from './constants/languages';

export const LANGUAGES = {
  en: { translation: en },
  hr: { translation: hr },
  sl: { translation: sl },
  sr: { translation: sr },
  cnr: { translation: sr },
  mk: { translation: mk },
} as const;

export type ILanguage = keyof typeof LANGUAGES;

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  compatibilityJSON: 'v3',
  returnNull: true,
  resources: LANGUAGES,
});

export function initI18n(languages: Array<ILanguage> = languageList.map(({ key }) => key)) {
  const targetLanguages = window.navigator.languages.map((lang) => lang.split('-')[0]);
  const newLanguage =
    window.localStorage.getItem('lang') ||
    targetLanguages.find((item) => languages.includes(item as unknown as ILanguage)) ||
    languages[0] ||
    'en';

  if (newLanguage !== i18n.language) {
    i18n.changeLanguage(newLanguage);
  }
}

export default i18n;
