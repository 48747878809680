import { FC, useMemo } from 'react';
import { HamburgerIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  useColorModeValue,
  MenuButton,
  Button,
  MenuList,
  Menu,
  Portal,
  chakra,
  Divider,
  Box,
  VStack,
  useDisclosure,
  Show,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { countries } from '@/constants/countries';
import { useUserStatus, UserStatus } from '@/hooks/useUserStatus';
import { ChartDetailLevel } from '@/interfaces/ChartDetailLevel';

import { Country } from '@/interfaces/Country';
import { useBrand } from '../../../hooks/useBrand';
import NextImage from 'next/image';
import { PreferencesModal } from './PreferencesModal';
import { ColorModeSelection } from '../selections/ColorModeSelection';
import { useKeys } from '../../../contexts/KeysContext';
import { IUserData } from '../../../interfaces/IUserData';

interface IOptionsProps {
  onOpen: () => void;
  country?: Country;
  activeCountry: {
    key: string;
  };
}

const Link = ({ href, children, active }: { href: string; children: string; active?: boolean }) => {
  const router = useRouter();

  return (
    <Box w="100%" borderLeft={active ? '4px solid #d82828' : '4px solid transparent'}>
      <Button variant="menuitem" onClick={() => router.push(href)} flex={1} ml={-1}>
        {children}
      </Button>
    </Box>
  );
};

const Options = ({ onOpen, country, activeCountry }: IOptionsProps) => {
  const { t } = useTranslation();
  const brand = useBrand();
  const { data: session } = useSession();
  const [isHR] = useUserStatus(brand);

  return (
    <>
      <Box w="100%">
        <Button variant="menuitem" onClick={onOpen} flex={1}>
          {t('common.settings')}
        </Button>
      </Box>
      {isHR === UserStatus.AuthenticatedHumanResources && (
        <Link
          href={`${
            country || process.env.NODE_ENV === 'development'
              ? ''
              : window.location.protocol + '//' + brand.domains.internal
          }/internal/${activeCountry.key}`}
        >
          Offers
        </Link>
      )}
      {session && (
        <Box w="100%">
          <Button variant="menuitem" onClick={() => signOut()} flex={1}>
            {t('common.logout')}
          </Button>
        </Box>
      )}
    </>
  );
};

const Image = chakra(NextImage, {
  shouldForwardProp: (prop) => ['width', 'height', 'src', 'alt'].includes(prop),
});

interface IPreferencesProps {
  details?: Array<ChartDetailLevel>;
  country?: Country;
  limitLanguages: Array<string>;
}

export const Preferences: FC<IPreferencesProps> = ({ country, limitLanguages, details }) => {
  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation();

  const { color, bg, focusBg } = useColorModeValue(
    { color: 'black', bg: ['gray.200', 'white'], focusBg: 'gray.300' },
    { color: 'white', bg: 'black', focusBg: 'gray.500' },
  );
  const keys = useKeys();
  const userData = keys.getKey('userData') as IUserData;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();

  const countryCode = country ?? (router.route.split(/\//g).pop() as Country);
  const activeCountry = useMemo(() => countries.find(({ key }) => key == countryCode) || countries[0], [countryCode]);

  const profileImage = session?.user?.image;
  const icon = profileImage ? (
    <Image alt="Menu" borderRadius="50%" src={profileImage} width={44} height={44} w="44px" h="44px" />
  ) : (
    <SettingsIcon />
  );

  if (!session?.user) {
    return (
      <Menu>
        <PreferencesModal
          isOpen={isOpen}
          onClose={onClose}
          limitLanguages={limitLanguages}
          country={country}
          details={details}
        />
        <Button
          bg={bg}
          _active={{ bg: focusBg }}
          _hover={{ bg: focusBg }}
          fontWeight={400}
          borderRadius="22px"
          h="44px"
          rightIcon={<></>}
          leftIcon={icon}
          pr={0}
          pl={4}
          w="44px"
          color={color}
          onClick={onOpen}
        />
      </Menu>
    );
  }

  return (
    <Menu autoSelect={false}>
      <PreferencesModal
        isOpen={isOpen}
        onClose={onClose}
        limitLanguages={limitLanguages}
        country={country}
        details={details}
      />
      <Show above="md">
        <MenuButton
          as={Button}
          bg={bg}
          _active={{ bg: focusBg }}
          _hover={{ bg: focusBg }}
          fontWeight={400}
          borderRadius="22px"
          h="44px"
          rightIcon={<></>}
          leftIcon={icon}
          pr={0}
          pl={4}
          w="44px"
          color={color}
        />
        <Portal>
          <MenuList bg={bg} border={0} boxShadow={`0 4px 8px rgba(0, 0, 0, 0.1)`} color={color} zIndex={2} mr={-0.5}>
            {session?.user && (
              <Box px={5} mb={5} mt={3}>
                <Box fontSize={18} fontWeight={700} lineHeight={1.4}>
                  {session.user.name}
                </Box>
                <Box fontSize={14} lineHeight={1.5} letterSpacing="0.2px">
                  {session.user.email}
                </Box>
              </Box>
            )}
            <Divider borderColor="#c4cede" />
            <VStack mt={2.5} mb={0.5}>
              <Options onOpen={onOpen} country={country} activeCountry={activeCountry} />
            </VStack>
          </MenuList>
        </Portal>
      </Show>
      <Show below="md">
        <Button
          bg={bg}
          _active={{ bg: focusBg }}
          _hover={{ bg: focusBg }}
          fontWeight={400}
          borderRadius="22px"
          h="44px"
          rightIcon={<></>}
          leftIcon={<HamburgerIcon />}
          pr={0}
          pl={4}
          w="44px"
          color={color}
          onClick={onMenuOpen}
        />
        <Drawer placement="bottom" onClose={onMenuClose} isOpen={isMenuOpen}>
          <DrawerOverlay />
          <DrawerContent bg={bg}>
            {session?.user && (
              <DrawerHeader borderBottomWidth="1px">
                <HStack>
                  <Box>{icon}</Box>
                  <Box px={5} mb={5} mt={3}>
                    <Box fontSize={18} fontWeight={700} lineHeight={1.4}>
                      {session.user.name}
                    </Box>
                    <Box fontSize={14} lineHeight={1.5} letterSpacing="0.2px">
                      {session.user.email}
                    </Box>
                  </Box>
                </HStack>
              </DrawerHeader>
            )}
            <DrawerBody p={0}>
              <VStack mt={4} mb={2}>
                <Box w="100%" pl={4}>
                  <ColorModeSelection />
                </Box>
                {userData.grant && (
                  <>
                    <Link active={router.pathname !== '/stocks'} href="/">
                      {t('common.salary')}
                    </Link>
                    <Link active={router.pathname === '/stocks'} href="/stocks">
                      {t('common.stocks')}
                    </Link>
                  </>
                )}
                <Options onOpen={onOpen} country={country} activeCountry={activeCountry} />
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Show>
    </Menu>
  );
};
