import { Box } from '@chakra-ui/react';
import Link from 'next/link';
import { ReactNode } from 'react';

export const HeaderItem = ({ children, href, active }: { children: ReactNode; href: string; active: boolean }) => (
  <Box borderTopWidth={4} borderTopColor={active ? 'brand.500' : 'transparent'}>
    <Link href={href}>
      <Box px={2} py={10}>
        <Box fontSize={16} lineHeight={1.5}>
          {children}
        </Box>
      </Box>
    </Link>
  </Box>
);
