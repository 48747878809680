import * as cookie from 'cookie';
import { NextPageContext } from 'next';

export function getCache(key: string, req: NextPageContext['req'], _res: NextPageContext['res']): string | null {
  const cookies = cookie.parse(req?.headers.cookie || '');

  const partsCount = parseInt(cookies[key] ?? '0', 10);

  if (!partsCount) {
    return null;
  }

  const parts = Array.from({ length: partsCount }, (_, i) => cookies[`${key}_${i}`]);

  return parts.join('');
}

// const COOKIE_OPTIONS = {
//   httpOnly: true,
//   secure: process.env.NODE_ENV === 'production',
//   sameSite: 'strict',
//   path: '/',
// } as const;

export function setCache(
  _key: string,
  _value: string,
  _req: NextPageContext['req'],
  _res: NextPageContext['res'],
  _maxAge: number,
): void {
  // const partsCount = Math.ceil(value.length / 2048);
  // const parts = Array.from({ length: partsCount }, (_, i) => value.slice(i * 2048, (i + 1) * 2048));
  // const cookies = [cookie.serialize(key, partsCount.toString(), { maxAge, ...COOKIE_OPTIONS })];
  // parts.forEach((part, i) => {
  //   cookies.push(cookie.serialize(`${key}_${i}`, part, { maxAge, ...COOKIE_OPTIONS }));
  // });
  // res?.setHeader('Set-Cookie', cookies);
}
