import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { FlagIcon } from '@/components/shared/FlagIcon';

export function MenuListSelection<TKeyType = string>({
  value,
  options,
  defaultValue,
  label,
  onChange,
}: {
  value?: (typeof options)[number]['key'];
  options: ReadonlyArray<{ key: TKeyType; label: string; flagPath?: string }>;
  defaultValue?: (typeof options)[number];
  label?: string;
  onChange: (value: (typeof options)[number]['key']) => void;
}) {
  const { t } = useTranslation();

  const activeItem = options.find(({ key }) => key === value) || defaultValue;

  const bg = useColorModeValue('gray.200', 'gray.600');
  const focusBg = useColorModeValue('#E4EBF5', 'gray.500');
  const activeBg = useColorModeValue('#C6D0E0', 'gray.500');

  return (
    <MenuGroup>
      <Stack textAlign="left" mb={7}>
        {label && (
          <Box fontSize={16} lineHeight={1.5} fontWeight={400}>
            {t(label)}
          </Box>
        )}
        <Menu closeOnSelect={false} gutter={0}>
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                _active={{ bg: focusBg }}
                _hover={{ bg: activeBg }}
                textAlign="left"
                fontWeight={700}
                borderRadius={isOpen ? '8px 8px 0px 0px' : 8}
                borderBottom={!isOpen ? '' : '1px solid #C6D0E0'}
                px={2.5}
                py={3.5}
                h="44px"
                fontSize={16}
                lineHeight={1.5}
                w={[300, 400, 400]}
                rightIcon={!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
                leftIcon={activeItem?.flagPath ? <FlagIcon src={activeItem.flagPath} /> : undefined}
              >
                {
                  <Box as="span" ml={2.5}>
                    {activeItem && t(activeItem.label)}
                  </Box>
                }
              </MenuButton>
              <MenuList bg={bg} p={0} borderWidth={0} borderRadius="0px 0px 8px 8px">
                {options?.map(({ label, flagPath, key }, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    icon={flagPath ? <FlagIcon src={flagPath} /> : undefined}
                    closeOnSelect
                    _focus={{ bg: activeBg }}
                    onClick={() => onChange(key)}
                    w={300}
                    bg={bg}
                  >
                    {t(label)}
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Stack>
    </MenuGroup>
  );
}
