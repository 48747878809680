import { Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { ContentLayout } from './Layout/ContentLayout';
import { Layout } from './Layout/Layout';

export class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  public state: { error: Error | null } = { error: null };

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  public render() {
    if (this.state.error) {
      const message =
        this.state.error.message === 'Invalid query string'
          ? 'The URL seems to be wrong, did you copy it correctly?'
          : this.state.error.message;

      return (
        <Layout limitLanguages={['en']}>
          <ContentLayout>
            <Heading>Something went wrong</Heading>
            <Text>{message || 'Unknown error'}</Text>
          </ContentLayout>
        </Layout>
      );
    }

    return this.props.children;
  }
}
