import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface IContentLayoutProps {
  children: ReactNode;
}

export const ContentLayout: FC<IContentLayoutProps> = ({ children }) => {
  const { bg, color } = useColorModeValue({ bg: 'white', color: 'black' }, { bg: 'black', color: 'white' });

  return (
    <Box bg={bg} pt={[0, 16]} color={color}>
      <Container maxW={840} px={[4, 2, 1]}>
        {children}
      </Container>
    </Box>
  );
};
