import {
  eurFormatterInstance,
  gbpFormatterInstance,
  hrkFormatterInstance,
  mkdFormatterInstance,
  usdFormatterInstance,
} from '@/utils/formatters';
import { HrkToEuro, MkdToEuro } from '@/constants/euro';
import { Currency as CurrencyEnum } from '@/enums/Currency';

type Currency = 'EUR' | 'kn' | 'MKD' | 'USD' | 'GBP';

export interface ICurrency {
  key: Currency;
  euroConversion: number;
  format(value: number): string;
  min: number;
  max: number;
  step: number;
  flagPath: string;
}

export const Eur: ICurrency = {
  key: 'EUR',
  euroConversion: 1,
  format: eurFormatterInstance.format.bind(eurFormatterInstance),
  min: 500,
  max: 100000,
  step: 50,
  flagPath: '/flags/eu.png',
};

export const Hrk: ICurrency = {
  key: 'kn',
  euroConversion: HrkToEuro,
  format: hrkFormatterInstance.format.bind(hrkFormatterInstance),
  min: 3500,
  max: 999999,
  step: 100,
  flagPath: '/flags/hr.svg',
};

export const Mkd: ICurrency = {
  key: 'MKD',
  euroConversion: MkdToEuro,
  format: mkdFormatterInstance.format.bind(mkdFormatterInstance),
  min: 22000,
  max: 999999,
  step: 1000,
  flagPath: '/flags/mk.svg',
};

export const Usd: ICurrency = {
  key: 'USD',
  euroConversion: 0.92,
  format: usdFormatterInstance.format.bind(usdFormatterInstance),
  min: 300,
  max: 100000,
  step: 50,
  flagPath: '/flags/us.svg',
};

export const Gbp: ICurrency = {
  key: 'GBP',
  euroConversion: 1.17,
  format: gbpFormatterInstance.format.bind(gbpFormatterInstance),
  min: 300,
  max: 100000,
  step: 50,
  flagPath: '/flags/gb.svg',
};

// export const currencies = [Eur, Hrk, Mkd, Usd] as const;
export const currencies: Record<Currency, ICurrency> = {
  EUR: Eur,
  kn: Hrk,
  MKD: Mkd,
  USD: Usd,
  GBP: Gbp,
} as const;

export const SPECIAL_CURRENCIES = [CurrencyEnum.None, CurrencyEnum.Local, CurrencyEnum.Default];

export const PRIMARY_LIST = [
  { key: CurrencyEnum.Default, label: 'common.currencies.default' },
  { key: CurrencyEnum.Local, label: 'common.currencies.local' },
  { key: CurrencyEnum.Eur, label: 'common.currencies.eur', flagPath: Eur.flagPath },
  { key: CurrencyEnum.Hrk, label: 'common.currencies.hrk', flagPath: Hrk.flagPath },
  { key: CurrencyEnum.Mkd, label: 'common.currencies.mkd', flagPath: Mkd.flagPath },
  { key: CurrencyEnum.Usd, label: 'common.currencies.usd', flagPath: Usd.flagPath },
];

export const SECONDARY_LIST = [...PRIMARY_LIST, { key: CurrencyEnum.None, label: 'common.currencies.none' }];
