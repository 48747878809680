import { Session } from 'next-auth';
import { IDebugData } from '../interfaces/IDebugData';
import { IUserData } from '../interfaces/IUserData';

const baseUrl = process.env.USER_DATA_API_URL ?? 'https://payroll-user-data.netlify.app/api/data';

export async function getUserData(session?: Session, options?: IDebugData): Promise<IUserData> {
  if (!session?.auth) {
    if (process.env.IS_DEV) {
      console.log('No token provided');
    }

    return {};
  }

  const userData = encodeURIComponent(JSON.stringify({ name: session.user?.name, image: session.user?.image }));
  const overrides = options
    ? encodeURIComponent(JSON.stringify({ email: options.user ? `${options.user}@infinum.com` : undefined }))
    : '';
  const url = `${baseUrl}?userData=${userData}&overrides=${overrides}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'x-api-key': process.env.USER_DATA_API_KEY ?? 'p3bERVSoPya5suHXgyTtX9EkbvT89AA0',
      authorization: encodeURIComponent(session?.auth),
    },
  });

  if (process.env.IS_DEV) {
    console.log('User data response:', res.status);
  }

  const response = (await res.json()) as IUserData | { error: number };

  if ('error' in response) {
    throw new Error(`Error: ${response.error}`);
  }

  return response;
}
