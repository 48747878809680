import coreTheme from '@/theme/core';
import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';

export default extendTheme({
  ...coreTheme,
  colors,
  fonts,
});
