export const colors = {
  brand: {
    200: '#5D2BFF',
    500: '#5D2BFF',
  },
  gray: {
    100: '#f4f9ff',
    200: '#e0e8f6',
    300: '#c6d0e0',
    400: '#90a0ba',
    500: '#657288',
    600: '#525f70',
  },
  floatingBg: {
    300: 'rgb(242, 245, 255)',
  },
  floatingText: {
    500: 'black',
  },
};
