import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface IHeaderContainerProps {
  children: ReactNode;
  mobileP?: number;
}

export const HeaderContainer: FC<IHeaderContainerProps> = ({ children, mobileP }) => {
  const { bg, color } = useColorModeValue({ bg: 'gray.100', color: 'black' }, { bg: 'black', color: 'white' });

  return (
    <Box bg={bg} color={color}>
      <Container maxW={1200} px={[mobileP ?? 5, mobileP ?? 5, 5]}>
        {children}
      </Container>
    </Box>
  );
};
