export const countries = [
  {
    flagPath: '/flags/hr.svg',
    key: 'hr',
    label: 'common.croatia',
  },
  {
    flagPath: '/flags/si.svg',
    key: 'si',
    label: 'common.slovenia',
  },
  {
    flagPath: '/flags/me.svg',
    key: 'me',
    label: 'common.montenegro',
  },
  {
    flagPath: '/flags/mk.svg',
    key: 'mk',
    label: 'common.macedonia',
  },
] as const;
