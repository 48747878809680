export const Switch = {
  variants: {
    colorMode: {
      thumb: {
        bg: 'white',
        w: 2.5,
        h: 2.5,
      },
      track: {
        bg: 'black',
        w: 22,
        h: 2.5,
        p: 0.5,

        _dark: {
          bg: 'whiteAlpha.300',
        },
      },
    },
  },
};
