export const colors = {
  gray: {
    100: '#f4f9ff',
    200: '#e0e8f6',
    300: '#c6d0e0',
    400: '#90a0ba',
    500: '#657288',
    600: '#525f70',
  },
};
